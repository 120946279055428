import React from 'react';
import { Link } from 'gatsby';
import { Button, Box } from '@material-ui/core';

const BlogLink = ({ to, isNext }) =>
  to ? (
    <Button
      component={Link}
      to={to}
      variant="contained"
      color="secondary"
      style={{
        marginLeft: isNext ? 'auto' : 0,
        flex: '0 1 120px',
      }}
    >
      {isNext ? 'next' : 'previous'}
    </Button>
  ) : null;

const PrevNextButtons = ({ previous, next }) => {
  return previous || next ? (
    <Box
      mt={5}
      px={{ xs: 2, sm: 2, md: 10, lg: 15, xl: 15 }}
      display="flex"
      justifyContent="space-between"
    >
      <BlogLink to={previous} />
      <BlogLink to={next} isNext />
    </Box>
  ) : null;
};

export default PrevNextButtons;
