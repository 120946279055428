import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Container } from '@material-ui/core';
import { get } from 'lodash';
import * as Accessa from 'accessa';
import ArchiveLayout from 'gatsby-theme-gentek/src/components/ArchiveLayout';
import PrevNextButtons from '../components/PrevNextButtons';
import { getBlogSlug } from '../utils';
import SEOHelmet from '../components/SEOHelmet';

const BlogArchive = ({ data, pageContext }) => {
  const { allContentfulBlogs } = data;
  const { prev, next } = pageContext;
  const title = 'News & Blogs';
  const description =
    'Industry insights and product knowledge';

  const lists = allContentfulBlogs.nodes.map((x) => ({
    onClick: () => navigate(getBlogSlug(x.title)),
    title: x.title,
    fluid: get(x, 'featuredImage.fluid', {
      src: '/NoImage.png',
    }),
    alt: get(x, 'featuredImage.title') || x.title,
  }));

  const prep = (str) => (str ? `/${str}` : str);

  return (
    <ArchiveLayout title={title} tagline={description}>
      <Container maxWidth="xl">
        <SEOHelmet
          title={title}
          description={description}
        />
        <Accessa.Features.Field
          xl={4}
          lg={4}
          lists={lists}
          spacing={1}
        />
        <PrevNextButtons
          previous={prep(prev)}
          next={prep(next)}
        />
      </Container>
    </ArchiveLayout>
  );
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulBlogs(
      sort: { order: DESC, fields: published }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        id
        featuredImage {
          fluid {
            srcSet
          }
          title
        }
        published
      }
    }
  }
`;

export default BlogArchive;
